// RESPONSE API
export const SUCCESSFUL = "SUCCESSFUL";
export const ERROR = "ERROR";

// AUTHORIZATION
export const AUTH_LOGIN = "AUTH_LOGIN";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const AUTH_REFRESH_TOKEN = "AUTH_REFRESH_TOKEN";

// LIST TASTING
export const GET_TASTING = "GET_TASTING";
export const DELETE_TASTING = "DELETE_TASTING";

// CREATE TASTING
export const CREATE_TASTING_SET_LANG = "CREATE_TASTING_SET_LANG";
export const CREATE_TASTING_REMOVE_DESCRIPTION =
  "CREATE_TASTING_REMOVE_DESCRIPTION";
export const CREATE_TASTING_SET_DESCRIPTIONS =
  "CREATE_TASTING_SET_DESCRIPTIONS";
export const CREATE_TASTING_SELECT_LANG = "CREATE_TASTING_SELECT_LANG";
export const CREATE_TASTING_SET_CODE = "CREATE_TASTING_SET_CODE";
export const CREATE_TASTING_SET_FILE = "CREATE_TASTING_SET_FILE";
export const CREATE_TASTING_REMOVE_FILE = "CREATE_TASTING_REMOVE_FILE";
export const CREATE_SELECT_TYPE = "CREATE_SELECT_TYPE";
export const CREATE_TASTING_SET_VISIT = "CREATE_TASTING_SET_VISIT";
export const CREATE_TASTING_SET_STORE = "CREATE_TASTING_SET_STORE";
export const CREATE_TASTING_RESET = "CREATE_TASTING_RESET";

//tasting videos
export const CREATE_TASTING_VIDEO_CHECK = "CREATE_TASTING_VIDEO_CHECK";
export const CREATE_TASTING_SET_TITLE1 = "CREATE_TASTING_SET_TITLE1";
export const CREATE_TASTING_SET_TITLE2 = "CREATE_TASTING_SET_TITLE2";
export const CREATE_TASTING_SET_ID_VIDEOS = "CREATE_TASTING_SET_ID_VIDEOS";
export const CREATE_TASTING_SET_IMAGE = "CREATE_TASTING_SET_IMAGE";
export const CREATE_TASTING_REMOVE_IMAGE = "CREATE_TASTING_REMOVE_IMAGE";

//tasting brochure
export const CREATE_TASTING_BROCHURE_CHECK = "CREATE_TASTING_BROCHURE_CHECK";
export const CREATE_TASTING_SET_TITLE1B = "CREATE_TASTING_SET_TITLE1B";
export const CREATE_TASTING_SET_TITLE2B = "CREATE_TASTING_SET_TITLE2B";
export const CREATE_TASTING_SET_BROCHURE = "CREATE_TASTING_SET_BROCHURE";
export const CREATE_TASTING_REMOVE_BROCHURE = "CREATE_TASTING_REMOVE_BROCHURE";
export const CREATE_TASTING_SET_IMAGEBROCHURE =
  "CREATE_TASTING_SET_IMAGEBROCHURE";
export const CREATE_TASTING_REMOVE_IMAGEBROCHURE =
  "CREATE_TASTING_REMOVE_IMAGEBROCHURE";

//STATES CONSTANTS
export const NOT_MODIFY = "NOT_MODIFY";
export const MODIFY = "MODIFY";
export const DELETE = "DELETE";

// EDIT TASTING
export const EDITE_SET_TASTING = "EDITE_SET_TASTING";
export const EDITE_TASTING_REMOVE_DESCRIPTION =
  "EDITE_TASTING_REMOVE_DESCRIPTION";
export const EDITE_TASTING_SET_DESCRIPTIONS = "EDITE_TASTING_SET_DESCRIPTIONS";
export const EDITE_TASTING_SELECT_LANG = "EDITE_TASTING_SELECT_LANG";
// export const EDITE_TASTING_SET_LANG = "EDITE_TASTING_SET_LANG";
export const EDITE_TASTING_SET_CODE = "EDITE_TASTING_SET_CODE";
export const EDITE_TASTING_SET_FILE = "EDITE_TASTING_SET_FILE";
export const EDITE_TASTING_REMOVE_FILE = "EDITE_TASTING_REMOVE_FILE";
export const EDITE_SELECT_TYPE = "EDITE_SELECT_TYPE";
export const EDITE_TASTING_SET_VISIT = "EDITE_TASTING_SET_VISIT";
export const EDITE_TASTING_SET_STORE = "EDITE_TASTING_SET_STORE";
export const EDITE_TASTING_RESET = "EDITE_TASTING_RESET";
//tasting videos
export const EDITE_TASTING_VIDEO_CHECK = "EDITE_TASTING_VIDEO_CHECK";
export const EDITE_TASTING_SET_TITLE1 = "EDITE_TASTING_SET_TITLE1";
export const EDITE_TASTING_SET_TITLE2 = "EDITE_TASTING_SET_TITLE2";
export const EDITE_TASTING_SET_ID_VIDEOS = "EDITE_TASTING_SET_ID_VIDEOS";
export const EDITE_TASTING_SET_IMAGE = "EDITE_TASTING_SET_IMAGE";
export const EDITE_TASTING_REMOVE_IMAGE = "EDITE_TASTING_REMOVE_IMAGE";
//tasting brochure
export const EDITE_TASTING_BROCHURE_CHECK = "EDITE_TASTING_BROCHURE_CHECK";
export const EDITE_TASTING_SET_TITLE1B = "EDITE_TASTING_SET_TITLE1B";
export const EDITE_TASTING_SET_TITLE2B = "EDITE_TASTING_SET_TITLE2B";
export const EDITE_TASTING_SET_BROCHURE = "EDITE_TASTING_SET_BROCHURE";
export const EDITE_TASTING_REMOVE_BROCHURE = "EDITE_TASTING_REMOVE_BROCHURE";
export const EDITE_TASTING_SET_IMAGEBROCHURE =
  "EDITE_TASTING_SET_IMAGEBROCHURE";
export const EDITE_TASTING_REMOVE_IMAGEBROCHURE =
  "EDITE_TASTING_REMOVE_IMAGEBROCHURE";

//VIDEOS
export const VIDEO_GET = "VIDEO_GET";
export const VIDEO_SELECT_LANG = "VIDEO_SELECT_LANG";
export const VIDEO_SET_TITLE1 = "VIDEO_SET_TITLE1";
export const VIDEO_SET_TITLE2 = "VIDEO_SET_TITLE2";
export const VIDEO_SET_IMAGE = "VIDEO_SET_IMAGE";
export const VIDEO_DELETE_IMAGE = "VIDEO_DELETE_IMAGE";
export const VIDEO_SET_ID = "VIDEO_SET_ID";
export const VIDEO_DELETE_ID = "VIDEO_DELETE_ID";

//BROCHURE
export const BROCHURE_GET = "BROCHURE_GET";
export const BROCHURE_REMOVE_PDF = "BROCHURE_REMOVE_PDF";
export const BROCHURE_SET_PDF = "BROCHURE_SET_PDF";
export const BROCHURE_SELECT_LANG = "BROCHURE_SELECT_LANG";
export const RESET_BROCHURE = "RESET_BROCHURE";
export const BROCHURE_SET_TITLE1 = "BROCHURE_SET_TITLE1";
export const BROCHURE_SET_TITLE2 = "BROCHURE_SET_TITLE2";
export const BROCHURE_SET_IMAGE = "BROCHURE_SET_IMAGE";
export const BROCHURE_DELETE_IMAGE = "BROCHURE_DELETE_IMAGE";

// NEWSLETTERS
export const LIST_SET_NEWSLETTERS = "LIST_SET_NEWSLETTERS";
