import {
  BROCHURE_GET,
  BROCHURE_REMOVE_PDF,
  BROCHURE_SET_PDF,
  BROCHURE_SELECT_LANG,
  RESET_BROCHURE,
  ERROR,
  BROCHURE_SET_TITLE1,
  BROCHURE_SET_TITLE2,
  BROCHURE_SET_IMAGE,
  BROCHURE_DELETE_IMAGE,
} from "../constant/types";

const initialState = {
  selectLang: null,
  brochures: [],
  title1: "",
  title2: "",
  brochure: null,
  image: null,
};

export default function brochureReducer(state = initialState, action) {
  switch (action.type) {
    case BROCHURE_GET:
      return {
        ...state,
        brochures: action.payload,
      };
    case BROCHURE_SELECT_LANG:
      return {
        ...state,
        selectLang: action.payload,
        brochure: state.brochures.find(
          (el) => el.language_id === action.payload.lang_id
        )
          ? state.brochures.find(
              (el) => el.language_id === action.payload.lang_id
            ).path
          : "",
        title1: state.brochures.find(
          (el) => el.language_id === action.payload.lang_id
        )
          ? state.brochures.find(
              (el) => el.language_id === action.payload.lang_id
            ).title1
          : "",
        title2: state.brochures.find(
          (el) => el.language_id === action.payload.lang_id
        )
          ? state.brochures.find(
              (el) => el.language_id === action.payload.lang_id
            ).title2
          : "",
        image: state.brochures.find(
          (el) => el.language_id === action.payload.lang_id
        )
          ? state.brochures.find(
              (el) => el.language_id === action.payload.lang_id
            ).path_image
          : null,
      };
    case BROCHURE_REMOVE_PDF:
      return {
        ...state,
        brochure: null,
      };
    case BROCHURE_SET_PDF:
      return {
        ...state,
        brochure: action.payload,
      };
    case BROCHURE_SET_TITLE1:
      return {
        ...state,
        title1: action.payload,
      };
    case BROCHURE_SET_TITLE2:
      return {
        ...state,
        title2: action.payload,
      };
    case BROCHURE_SET_IMAGE:
      return {
        ...state,
        image: action.payload,
      };
    case BROCHURE_DELETE_IMAGE:
      return {
        ...state,
        image: null,
      };
    case RESET_BROCHURE:
      return {
        ...state,
        selectLang: null,
        // brochures: [],
        brochure: null,
        image: null,
      };
    case ERROR:
      return {
        ...state,
      };
    default:
      return state;
  }
}
