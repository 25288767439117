import { AUTH_REFRESH_TOKEN, AUTH_LOGIN, AUTH_LOGOUT } from "../constant/types";

const initialState = {
  loggedIn: false,
  user: {},
};
const AuthReducer = (state = initialState, actions) => {
  switch (actions.type) {
    case AUTH_LOGIN:
      return { ...state, loggedIn: true, user: actions.payload };
    case AUTH_LOGOUT:
      return { ...state, loggedIn: false, user: {} };
    case AUTH_REFRESH_TOKEN:
      return {
        ...state,
        loggedIn: true,
        user: actions.payload,
      };
    default:
      return state;
  }
};

export default AuthReducer;
