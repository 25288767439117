import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import Header from "../Header";

const PrivateRoute = ({ component: Component, ...remainingProps }) => {
  const loggedIn = useSelector((state) => state.AuthReducer.loggedIn);
  console.log(loggedIn);
  return (
    <Route
      {...remainingProps}
      render={({ location }) =>
        loggedIn === true ? (
          <Header>
            <Component {...remainingProps} />
          </Header>
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};
export default PrivateRoute;
