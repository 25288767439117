import { ERROR, LIST_SET_NEWSLETTERS } from "../constant/types";

const initialState = {
  newsletters: [],
};
export default function listNewsletters(state = initialState, action) {
  switch (action.type) {
    case LIST_SET_NEWSLETTERS:
      return {
        ...state,
        newsletters: action.payload,
      };

    case ERROR:
      return {
        ...state,
      };
    default:
      return state;
  }
}
