import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

const GuestRoute = ({ component: Component, ...remainingProps }) => {
  const loggedIn = useSelector((state) => state.AuthReducer.loggedIn);
  console.log(loggedIn);
  return (
    <Route
      {...remainingProps}
      render={({ location }) =>
        loggedIn === false ? (
          <Component {...remainingProps} />
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};
export default GuestRoute;
