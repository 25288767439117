import React, { useState, useRef } from "react";
import { Link as RouterLink } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Link from "@material-ui/core/Link";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import IconButton from "@material-ui/core/IconButton";

// icons
import AccountCircle from "@material-ui/icons/AccountCircle";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";

import { useSelector, useDispatch } from "react-redux";
import cookie from "js-cookie";

import ScrollToTop from "./ScrollToTop";
import { AUTH_LOGOUT } from "../constant/types";
import { Button } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  barraHeader: {
    width: "100%",
    height: "80px",
    display: "flex",
    justifyContent: "flex-end",
    backgroundColor: "transparent",
    // boxShadow: "0px 5px 5px #00000029",
    boxShadow: "none",
    [theme.breakpoints.down("sm")]: {
      height: "64px",
    },
  },
  toolbar: {
    maxWidth: 1100,
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    height: "100%",
    // padding: 0,
    margin: "auto",
    opacity: 1,
  },
  containerImgFirma: {
    width: "100%",
    height: "100%",
    margin: "auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  imgFirma: {
    height: 31,
    [theme.breakpoints.up("md")]: {
      height: 60,
    },
  },
  containerNav: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  botones: {
    width: "calc(100% - 32px)",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    position: "relative",
    alignItems: "center",
    [theme.breakpoints.up(1100)]: {
      width: "100%",
    },
  },
  link: {
    textDecoration: "none",
    "&:hover": {
      textDecoration: "none",
    },
  },

  // Main
  main: {
    flexGrow: 1,
    maxHeight: "calc(100vh - 64px)",
    height: "100%",
    overflow: "scroll",
    paddingTop: 64,
    padding: "64px 15px 0px",
    [theme.breakpoints.up("md")]: {
      maxHeight: "calc(100vh - 80px)",
      paddingTop: 80,
      // paddingLeft: 135,
    },
  },
  // user
  item: {
    width: "max-content",
    fontFamily: "Whitney-Book",
    backgroundColor: "#d4b68e",
    padding: "3px 8px",
    color: "#312A2D",
    display: "flex",
    textTransform: "none",
    alignItems: "center",
    letterSpacing: "1.5px",
    "&:hover":{
      backgroundColor: "#d4b68e",
    },
    "& svg": {
      color: "#D4B68E",
      height: 15,
      marginRight: 5,
    },
  },
  item2: {
    width: "max-content",
    fontFamily: "Whitney-Book",
    padding: "3px 8px",
    color: "#312A2D",
    display: "flex",
    textTransform: "none",
    alignItems: "center",
    letterSpacing: "1.5px",
    "& svg": {
      color: "#D4B68E",
      height: 15,
      marginRight: 5,
    },
  },
}));

const HeaderHome = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const loggedIn = useSelector((store) => store.AuthReducer.loggedIn);
  const user = useSelector((store) => store.AuthReducer.user);
  const refToolbar = useRef();
  const [openMenu, setOpenMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleLogout = (e) => {
    e.preventDefault();
    setAnchorEl(null);
    cookie.remove("token");
    dispatch({ type: AUTH_LOGOUT });
  };
  const handleMenu = (event) => {
    setOpenMenu(true);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setOpenMenu(false);
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <AppBar position="fixed" className={classes.barraHeader}>
        <Toolbar id="toolbar" ref={refToolbar} className={classes.toolbar}>
          <nav className={classes.botones}>
            <div className={classes.containerImgFirma}>
              <Link
                className={classes.link}
                to={{ pathname: "/" }}
                component={RouterLink}
              >
                <img
                  className={classes.imgFirma}
                  src={require("../assets/firma.png").default}
                  alt={"firma Susana"}
                />
              </Link>
            </div>

            {loggedIn && (
              <div className={classes.containerNav}>
                <Link
                  className={classes.link}
                  to={window.location.pathname === "/" ? "/db" : "/"}
                  component={RouterLink}
                >
                  <Button className={classes.item}>
                    {window.location.pathname === "/"
                      ? "Data Base"
                      : "List of Tasting"}
                  </Button>
                </Link>

                <IconButton
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleMenu}
                  // color="#D4B68E"
                >
                  <AccountCircle style={{ color: "#D4B68E" }} />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  open={openMenu}
                  onClose={handleClose}
                >
                  <MenuItem className={classes.item2} onClick={handleClose}>
                    <PersonOutlineIcon /> {"  "}
                    {user.name.charAt(0).toUpperCase() + user.name.slice(1)}
                  </MenuItem>
                  <MenuItem className={classes.item2} onClick={handleLogout}>
                    <ExitToAppIcon />
                    {"  "} Logout
                  </MenuItem>
                </Menu>
              </div>
            )}
          </nav>
        </Toolbar>
      </AppBar>
      <ScrollToTop>
        <main className={classes.main}>{props.children}</main>
      </ScrollToTop>
    </React.Fragment>
  );
};

export default HeaderHome;
