import React from "react";
import { BrowserRouter as Router, Redirect, Switch } from "react-router-dom";
import loadable from "@loadable/component";

import PrivateRoute from "./components/auth/PrivateRoute";
import GuestRoute from "./components/auth/GuestRoute";

const Login = loadable(() => import("./views/Login/Login"));
const TastingList = loadable(() => import("./views/Tasting/TastingList"));
const NewsletterList = loadable(() => import("./views/Newsletter/Newsletter"));

function App() {
  return (
    <Router>
      <Switch>
        <GuestRoute exact path="/login" component={Login} />
        {/* tasting */}
        <PrivateRoute exact path="/" component={TastingList} />
        <PrivateRoute exact path="/db" component={NewsletterList} />
        <Redirect to="/" />
        {/* si el path no hace match con alguna de las rutas anteriores lo redirige al home */}
      </Switch>
    </Router>
  );
}

export default App;
