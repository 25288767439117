import { GET_TASTING, ERROR, DELETE_TASTING } from "../constant/types";

const initialState = {
  tasting: [],
  loading: true,
};

export default function listTasting(state = initialState, action) {
  switch (action.type) {
    case GET_TASTING:
      return {
        ...state,
        tasting: action.payload,
      };
    case DELETE_TASTING:
      return {
        ...state,
        tasting: state.tasting.filter((elem) => elem.id !== action.payload),
      };
    case ERROR:
      return {
        ...state,
      };
    default:
      return state;
  }
}
