import {
  EDITE_SET_TASTING,
  EDITE_TASTING_REMOVE_DESCRIPTION,
  EDITE_TASTING_SET_DESCRIPTIONS,
  EDITE_TASTING_SELECT_LANG,
  EDITE_SELECT_TYPE,
  EDITE_TASTING_SET_CODE,
  ERROR,
  EDITE_TASTING_SET_FILE,
  EDITE_TASTING_REMOVE_FILE,
  EDITE_TASTING_SET_VISIT,
  EDITE_TASTING_SET_STORE,
  EDITE_TASTING_VIDEO_CHECK,
  EDITE_TASTING_SET_TITLE1,
  EDITE_TASTING_SET_TITLE2,
  EDITE_TASTING_SET_ID_VIDEOS,
  EDITE_TASTING_SET_IMAGE,
  EDITE_TASTING_REMOVE_IMAGE,
  EDITE_TASTING_BROCHURE_CHECK,
  EDITE_TASTING_SET_BROCHURE,
  EDITE_TASTING_REMOVE_BROCHURE,
  CREATE_TASTING_SET_LANG,
  EDITE_TASTING_RESET,
  EDITE_TASTING_SET_TITLE1B,
  EDITE_TASTING_SET_TITLE2B,
  EDITE_TASTING_SET_IMAGEBROCHURE,
  EDITE_TASTING_REMOVE_IMAGEBROCHURE,
} from "../constant/types";

const initialState = {
  lang: [],
  selectLang: null,
  types: ["tourism", "special", "event"],
  selectType: null,
  code: "",
  details: "",
  visit: "",
  store: "",
  file: null,

  brochure_id: null,
  title1b: "",
  title2b: "",
  brochure: null,
  imageBrochure: null,
  brochureCheck: false,

  video_id: null,
  title1: "",
  title2: "",
  id_youtube: "",
  image: null,
  videoCheck: false,
};

export default function editTasting(state = initialState, action) {
  switch (action.type) {
    case CREATE_TASTING_SET_LANG:
      return {
        ...state,
        lang: action.payload,
      };
    case EDITE_SET_TASTING:
      return {
        ...state,
        selectLang: state.lang.find(
          (elem) => elem.code === action.payload.language_code
        ),
        selectType: action.payload.type,
        tasting_id: action.payload.tasting_id,
        code: action.payload.code,
        details: action.payload.name,
        visit: action.payload.visit,
        store: action.payload.store,
        file: action.payload.path_ficha_tecnica,
        brochure_id: action.payload.brochure_id,
        title1b: action.payload.brochure_title1,
        title2b: action.payload.brochure_title2,
        brochure: action.payload.path_brochure,
        imageBrochure: action.payload.brochure_path_image,
        brochureCheck: action.payload.path_brochure ? true : false,
        video_id: action.payload.video_id,
        title1: action.payload.video_title1,
        title2: action.payload.video_title2,
        id_youtube: action.payload.video_id_youtube,
        image: action.payload.video_path_image,
        videoCheck: action.payload.video_id_youtube ? true : false,
      };
    case EDITE_TASTING_SELECT_LANG:
      return {
        ...state,
        selectLang: state.lang.find((elem) => elem.code === action.payload),
        file: null,
      };
    case EDITE_SELECT_TYPE:
      return {
        ...state,
        selectType: action.payload,
      };

    case EDITE_TASTING_SET_CODE:
      return {
        ...state,
        code: action.payload,
      };
    case EDITE_TASTING_SET_DESCRIPTIONS:
      return {
        ...state,
        details: action.payload,
      };
    case EDITE_TASTING_SET_VISIT:
      return {
        ...state,
        visit: action.payload,
      };
    case EDITE_TASTING_SET_STORE:
      return {
        ...state,
        store: action.payload,
      };
    case EDITE_TASTING_REMOVE_DESCRIPTION:
      return {
        ...state,
        details: action.payload,
      };
    case EDITE_TASTING_SET_FILE:
      return {
        ...state,
        file: action.payload,
      };
    case EDITE_TASTING_REMOVE_FILE:
      return {
        ...state,
        file: null,
      };
    // set video
    case EDITE_TASTING_VIDEO_CHECK:
      return {
        ...state,
        videoCheck: action.payload,
      };
    case EDITE_TASTING_SET_TITLE1:
      return {
        ...state,
        title1: action.payload,
      };
    case EDITE_TASTING_SET_TITLE2:
      return {
        ...state,
        title2: action.payload,
      };
    case EDITE_TASTING_SET_ID_VIDEOS:
      return {
        ...state,
        id_youtube: action.payload,
      };
    case EDITE_TASTING_SET_IMAGE:
      return {
        ...state,
        image: action.payload,
      };
    case EDITE_TASTING_REMOVE_IMAGE:
      return {
        ...state,
        image: null,
      };
    // set brochure
    case EDITE_TASTING_BROCHURE_CHECK:
      return {
        ...state,
        brochureCheck: action.payload,
      };
    case EDITE_TASTING_SET_BROCHURE:
      return {
        ...state,
        brochure: action.payload,
      };
    case EDITE_TASTING_SET_TITLE1B:
      return {
        ...state,
        title1b: action.payload,
      };
    case EDITE_TASTING_SET_TITLE2B:
      return {
        ...state,
        title2b: action.payload,
      };
    case EDITE_TASTING_REMOVE_BROCHURE:
      return {
        ...state,
        brochure: null,
      };
    case EDITE_TASTING_SET_IMAGEBROCHURE:
      return {
        ...state,
        imageBrochure: action.payload,
      };
    case EDITE_TASTING_REMOVE_IMAGEBROCHURE:
      return {
        ...state,
        imageBrochure: null,
      };
    case EDITE_TASTING_RESET:
      return {
        ...state,
        selectLang: null,
        types: ["tourism", "special", "event"],
        selectType: null,
        code: "",
        details: "",
        visit: "",
        store: "",
        file: null,

        brochure_id: null,
        title1b: "",
        title2b: "",
        brochure: null,
        imageBrochure: null,
        brochureCheck: false,

        video_id: null,
        title1: "",
        title2: "",
        id_youtube: "",
        image: null,
        videoCheck: false,
      };
    case ERROR:
      return {
        ...state,
      };
    default:
      return state;
  }
}
