import { combineReducers } from "redux";
import AuthReducer from "./AuthReducer";
import TastingReducer from "./TastingReducer";
import CreateTastingReducer from "./CreateTastingReducer";
import EditTastingReducer from "./EditTastingReducer";
import NewsletterReducer from "./NewsletterReducer";
import VideoReducer from "./VideoReducer";
import BrochureReducer from "./BrochureReducer";

export default combineReducers({
  AuthReducer: AuthReducer,
  TastingReducer: TastingReducer,
  CreateTastingReducer: CreateTastingReducer,
  EditTastingReducer: EditTastingReducer,
  NewsletterReducer: NewsletterReducer,
  VideoReducer: VideoReducer,
  BrochureReducer: BrochureReducer,
});
