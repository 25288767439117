import {
  VIDEO_SET_TITLE1,
  VIDEO_SET_TITLE2,
  VIDEO_SET_IMAGE,
  VIDEO_DELETE_IMAGE,
  VIDEO_SET_ID,
  VIDEO_SELECT_LANG,
  ERROR,
  VIDEO_GET,
} from "../constant/types";

const initialState = {
  selectLang: null,
  title1: "",
  title2: "",
  image: null,
  videos: [],
  video: "",
};

export default function createVideoReducer(state = initialState, action) {
  switch (action.type) {
    case VIDEO_GET:
      return{
        ...state,
        videos:action.payload
      }
    case VIDEO_SELECT_LANG:
      return {
        ...state,
        selectLang: action.payload,
        title1: state.videos.find(
          (el) => el.language_id === action.payload.lang_id
        )
          ? state.videos.find((el) => el.language_id === action.payload.lang_id)
              .title1
          : "",
        title2: state.videos.find(
          (el) => el.language_id === action.payload.lang_id
        )
          ? state.videos.find((el) => el.language_id === action.payload.lang_id)
              .title2
          : "",
        image: state.videos.find(
          (el) => el.language_id === action.payload.lang_id
        )
          ? state.videos.find((el) => el.language_id === action.payload.lang_id)
              .path_image
          : null,
        video: state.videos.find(
          (el) => el.language_id === action.payload.lang_id
        )
          ? state.videos.find((el) => el.language_id === action.payload.lang_id)
              .id_youtube
          : "",
      };
    case VIDEO_SET_TITLE1:
      return {
        ...state,
        title1: action.payload,
      };
    case VIDEO_SET_TITLE2:
      return {
        ...state,
        title2: action.payload,
      };
    case VIDEO_SET_IMAGE:
      return {
        ...state,
        image: action.payload,
      };

    case VIDEO_DELETE_IMAGE:
      return {
        ...state,
        image: null,
      };

    case VIDEO_SET_ID:
      return {
        ...state,
        video: action.payload,
      };

    case "RESET_VIDEOS":
      return {
        ...state,
        selectLang: null,
        title1: "",
        title2: "",
        image: null,
        video: "",
      };
    case "RESET_FORM":
      return {
        ...state,
        selectLang: null,
        title1: "",
        title2: "",
        image: null,
        video: "",
      };
    case ERROR:
      return {
        ...state,
      };
    default:
      return state;
  }
}
